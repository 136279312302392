module.exports = [{
      plugin: require('../node_modules/gatsby-source-google-docs/gatsby-browser.js'),
      options: {"plugins":[],"folder":"1oJbngS6qw0nb-hGvpY8I0s8e-4UXOPEU","createPages":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-unwrap-images","gatsby-remark-images","gatsby-remark-gifs"],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/vicehazdovac/Projects/marko/just-vegan-recipes"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YEX6B36TXC"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"just-vegan-recipes","short_name":"just-vegan-recipes","start_url":"/","background_color":"#FBFBFB","theme_color":"#55B435","display":"minimal-ui","icon":"./static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"989b9e6c3d7c182e9573d4373b78da33"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
